<template>
  <v-container fluid>
    <!--HEADER-->
    <v-app-bar color="#002A53" flat app dense height="40px" dark>
      <v-btn icon :to="{ name: 'rde' }">
        <v-icon>mdi-home-outline</v-icon>
      </v-btn>
      <v-toolbar-title>Números Importantes</v-toolbar-title>      
    </v-app-bar>
    <v-card class="pa-2 py-1" > 
      <v-row class="ma-2 mx-0 mt-0">
            <v-chip label small dark v-for="(itemtelefono, i) in itemTelefonos" :key="`${i}-${itemtelefono.id}`" color="#002A53" class="ma-1 pr-2" :href="itemtelefono.hr">
                   <v-avatar left tile>
                      <v-icon>{{itemtelefono.icono}}</v-icon>
                    </v-avatar>
                            {{itemtelefono.texto}} 
                    <v-avatar tile  >
                      <v-icon small >{{itemtelefono.icono2}}</v-icon>
                    </v-avatar>
                              {{itemtelefono.texto2}}
                    <template v-if="itemtelefono.texto3">  
                    <v-avatar tile  >
                      <v-icon small >{{itemtelefono.icono3}}</v-icon>
                    </v-avatar>
                              {{itemtelefono.texto3}}
                    </template>
                    
          </v-chip>
      </v-row>      
    <v-divider class="ma-1"></v-divider>    
          <v-row class="caption ma-0 pa-1 "  >
            Por cobertura de emergencias de área protegida dentro del complejo comunicarse a Recepción       
          </v-row>
          <v-row class="ma-2 mx-0 mt-0">   
              <v-chip label tile small dark  color="#002A53" class="ma-1 pr-2   " >
                      <v-avatar small left>
                        <v-icon>mdi-ambulance</v-icon>
                      </v-avatar>
                              Sanatorio Cantegril
                      <v-avatar  >
                        <v-icon small >mdi-phone</v-icon>
                      </v-avatar>
                              42 489151
            </v-chip>  
            <v-chip label small dark color="#002A53"  class="ma-1 pr-2 " href="https://wa.me/093755353?text=">
                      <v-avatar left>
                        <v-icon >mdi-ambulance</v-icon>
                      </v-avatar>
                              Sanatorio Mautone
                      <v-avatar  >
                        <v-icon small >mdi-phone</v-icon>
                      </v-avatar>
                              42225353
                        <v-avatar  >
                        <v-icon small >mdi-whatsapp</v-icon>                
                      </v-avatar>093755353
            </v-chip>
            <v-chip label small dark color="#002A53" class="ma-1 pr-2 " >
                      <v-avatar left>
                        <v-icon  >mdi-ambulance</v-icon>
                      </v-avatar>
                            Hospital de Maldonado
                      <v-avatar  >
                        <v-icon small >mdi-phone</v-icon>
                      </v-avatar>
                              42 225859
            </v-chip>
         </v-row>
      
       <v-divider class="ma-1"></v-divider>
       <v-row class=" caption mx-0 pa-1">
                Atención e información por Covid 19:
       </v-row>
       <v-row class="ma-0 "> 
              <v-chip label small dark  color="#002A53" class="ma-1   pr-2" link href="https://wa.me/098999999?text=">
                    <v-avatar left >
                      <v-icon small>mdi-phone</v-icon>
                    </v-avatar>
                          0800-1919 
                    <v-avatar  >
                      <v-icon small >mdi-whatsapp</v-icon>
                    </v-avatar>
                            098-999999                    
              </v-chip >
              <v-chip label small dark  color="#002A53" class="ma-1 pr-2"  link href="https://www.gub.uy/ministerio-salud-publica/coronavirus">
                        <v-avatar left>
                          <v-icon small >mdi-web</v-icon>
                        </v-avatar>                   
                                www.gub.uy/ministerio-salud-publica/coronavirus
              </v-chip>         
       </v-row>
      
    </v-card>    
  </v-container>
</template>
<script>
//import axios from 'axios'
export default {
  data() {
    return {
      itemTelefonos: [
        { id:"1",texto: "Recepción", icono: "mdi-room-service-outline", texto2:"0/210",icono2:"mdi-phone",texto3:"59895861845",icono3:"mdi-whatsapp",hr:"https://wa.me/59895861845?text=" },
         {id:"2", texto: "Restaurante", icono: "mdi-silverware", texto2:"200",icono2:"mdi-phone" },       
        {id:"3", texto: "Rincón de los niños", icono: "mdi-baby-face-outline", texto2:"198",icono2:"mdi-phone" },
       {id:"4", texto: "Drugstore", icono: "mdi-store", texto2:"206",icono2:"mdi-phone" },
       {id:"5", texto: "Departamento de Recreación", icono: "mdi-bike", texto2:"194",icono2:"mdi-phone" }
      ]
   
    }
  },
  methods:{
            
  }
}
</script>
